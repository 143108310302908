/*
This file is made by Frenify
This css file includes all base styles and template's main color (in this tempalte main color is pink(#f00a77)). 
Template's main color styles located at the bottom of this file.
*/
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,font,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td
	{
	background: transparent;
	border: 0;
	margin: 0;
	padding: 0;
	vertical-align: baseline;
	outline: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
    display: block;
}

blockquote {
	quotes: none;
}

blockquote:before,blockquote:after {
	content: '';
	content: none;
}
ul, ol{
	margin:30px;	
}
ul li, ol li{
	margin-bottom:5px;
}
img{
	vertical-align:middle;
	max-width:100%;
}
iframe{
	max-width:100%;
}
del {
	text-decoration: line-through;
}
a, i{
	-webkit-transition: all .3s ease;
	   -moz-transition: all .3s ease;
	    -ms-transition: all .3s ease;
	     -o-transition: all .3s ease;
	        transition: all .3s ease;
	border:none;
}
a:hover{text-decoration:none;}

h1, h2, h3, h4, h5, h6{
	font-weight:700;
	color:#333;
	margin-bottom:20px;
}
h1 { font-size: 45px; }
h2 { font-size: 36px; }
h3 { font-size: 30px; }
h4 { font-size: 24px; }
h5 { font-size: 20px; }
h6 { font-size: 16px; }

table{
	width:100%;
	max-width:100%;
	text-align:left;
	border-collapse: collapse;
	border-spacing: 0px;
	margin-bottom:15px;
	color:#999;
	border:1px solid #eee;
}
table tr{
	border:1px solid #eee;
}
table th{
	vertical-align:top;
	color:#333;
	background:none;
	font-size:16px;
	font-weight:normal;
	padding:15px 10px;
	border:1px solid #eee;
}
table td{
	vertical-align:top;
	padding:10px;
	border:1px solid #eee;
}

code, pre {
	padding: 10px;
	padding-bottom: 0;
	border-left: 3px solid #6FBF71;
	border-bottom: 1px solid transparent;
	background: #f7f7f7;
	overflow-x:scroll;
}

pre{
	display: block;	
	word-break: break-all;
	word-wrap: break-word;
}
pre code { white-space: pre-wrap; }

p{
	letter-spacing:1px;
}


.space10{width:100%; float:left; clear:both; margin-bottom:10px;}
.space20{width:100%; float:left; clear:both; margin-bottom:20px;}
.space30{width:100%; float:left; clear:both; margin-bottom:30px;}
.space40{width:100%; float:left; clear:both; margin-bottom:40px;}
.space50{width:100%; float:left; clear:both; margin-bottom:50px;}
.space60{width:100%; float:left; clear:both; margin-bottom:60px;}
.space70{width:100%; float:left; clear:both; margin-bottom:70px;}
.space80{width:100%; float:left; clear:both; margin-bottom:80px;}
.space90{width:100%; float:left; clear:both; margin-bottom:90px;}
.space100{width:100%; float:left; clear:both; margin-bottom:100px;}
.space200{width:100%; float:left; clear:both; margin-bottom:200px;}
.space300{width:100%; float:left; clear:both; margin-bottom:300px;}
.space400{width:100%; float:left; clear:both; margin-bottom:400px;}
.space500{width:100%; float:left; clear:both; margin-bottom:500px;}
.space1000{width:100%; float:left; clear:both; margin-bottom:1000px;}


/*------------------------------------------------------------------*/ 
/*	x) FORM ELEMENTS
/*------------------------------------------------------------------*/
textarea{
	border-radius:1px;
	font-size: 16px;
	color: #ccc;
	resize: vertical;
	font-family: 'Jost', Arial, Helvetica, sans-serif;
	background-color: transparent;
	
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	-ms-box-sizing: border-box;     /* IE 10 + */
	box-sizing: border-box;         /* Opera/IE 8+ */
	
	line-height: 1.5;
	padding:16px 20px!important;
	width:100%;
	border: 1px solid rgba(255,255,255,.2);
	
	-webkit-transition: all .3s ease;
	   -moz-transition: all .3s ease;
	    -ms-transition: all .3s ease;
	     -o-transition: all .3s ease;
	        transition: all .3s ease;
}
input[type="text"],
input[type="phone"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
	width: 100%;
    display: block;
    border-radius: 1px;
    background-color: transparent;
    border: 1px solid rgba(255,255,255,.2);
    color: #ccc;
    font-size: 16px;
    outline: none;
    padding: 0 20px !important;
    line-height: 70px;
    height: 70px;
	letter-spacing: 0.5px;
	
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	-ms-box-sizing: border-box;     /* IE 10 + */
	box-sizing: border-box;         /* Opera/IE 8+ */
	
	font-family:'Jost', Arial, Helvetica, sans-serif;
	
	vertical-align: middle;
	
	-webkit-transition: all .3s ease;
	   -moz-transition: all .3s ease;
	    -ms-transition: all .3s ease;
	     -o-transition: all .3s ease;
	        transition: all .3s ease;
	
}
textarea:hover,
input[type="text"]:hover,
input[type="phone"]:hover,
input[type="password"]:hover,
input[type="datetime"]:hover,
input[type="datetime-local"]:hover,
input[type="date"]:hover,
input[type="month"]:hover,
input[type="time"]:hover,
input[type="week"]:hover,
input[type="number"]:hover,
input[type="email"]:hover,
input[type="url"]:hover,
input[type="search"]:hover,
input[type="tel"],
input[type="color"]:hover,
.uneditable-input:hover{
	border-color: rgba(255,255,255,.4);
}

textarea:focus,
input[type="text"]:focus,
input[type="phone"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
	border-color: #ff79c6;
	outline: none;
}


input[type="submit"]{
	padding: 13px 15px;
	height: 40px;
	line-height: 1;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 600;
	text-decoration: none;
	letter-spacing: 0.5px;
	border:none;
	cursor:pointer;
	color: #FFF;
	background:#777;
	
	-webkit-transition: all .3s ease;
	   -moz-transition: all .3s ease;
	    -ms-transition: all .3s ease;
	     -o-transition: all .3s ease;
	        transition: all .3s ease;
}
input[type="submit"]:hover{
	background:#444;
}
select{
	padding:8px;
	border:2px solid #eee;
}
input[type="button"]{
	cursor:pointer;
	-webkit-transition: all .3s ease;
	   -moz-transition: all .3s ease;
	    -ms-transition: all .3s ease;
	     -o-transition: all .3s ease;
	        transition: all .3s ease;
}
.container{
	padding: 0 12%;
	margin: 0 auto;
	width: 100%;
}
.container.noright{
	padding-right: 0;
}
.max200{max-width: 200px;}
.max250{max-width: 250px;}
.max300{max-width: 300px;}
.max350{max-width: 350px;}
.max400{max-width: 400px;}
.max450{max-width: 450px;}
.max500{max-width: 500px;}
.max550{max-width: 550px;}
.max600{max-width: 600px;}
.max650{max-width: 650px;}
.max700{max-width: 700px;}
.max750{max-width: 750px;}
.max800{max-width: 800px;}
.max850{max-width: 850px;}
.max880{max-width: 880px;}
.max900{max-width: 900px;}
.max950{max-width: 950px;}
.max1000{max-width: 1000px;}
.max1020{max-width: 1020px;}
.max1050{max-width: 1050px;}
.max1100{max-width: 1100px;}
.max1150{max-width: 1150px;}
.max1200{max-width: 1200px;}
.max1250{max-width: 1250px;}
.max1300{max-width: 1300px;}
.max1350{max-width: 1350px;}
.max1400{max-width: 1400px;}
.max1450{max-width: 1450px;}
.max1500{max-width: 1500px;}
.roww,
section{
	width: 100%;
	float: left;
	clear: both;
}
.clearfix:after,
.clearfix:before{
	content: '';
	clear: both;
	display: table;
}